<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담유형별(시간대별)통계</h1>
    <div class="box-wrap d-flex">
      <div>
        <div class="box-ct d-flex">
          <div class="">
            <div class="form-inp sm">
              <v-select
                :items="ASP_CUST_KEY"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                v-on:change="chkAsp"
              >
                <template v-slot:label>
                  회사구분
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                content-class="calendar-modal"
                color="#FFF"
              >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar ml-2"
                    append-icon="svg-calendar"
                  >
                      <template v-slot:label>
                              상담일자
                          </template>
                  </v-text-field>
                </template>
                <div class="datepicker-range">
                  <v-date-picker
                      v-model="dates[0]"
                      no-title
                      dark
                      color="#F7AFAE"
                      @change="startDate"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  <v-date-picker
                      v-model="dates[1]"
                      dark
                      color="#F7AFAE"
                      no-title
                      show-current="false"
                      :min="dates[0]"
                      @change="endDate"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                </div>
                <div class="text-center pt-3 pb-3">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="menu1 = false"
                  >확인</v-btn>
                </div>
              </v-menu>
            <div class="form-inp sm ml-2">
              <v-select
                :items="CNSL_TY1"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="CNSL_NEWTY1"
                v-on:change="getConsultTY('1')"
              >
                <template v-slot:label>
                  상담유형
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="CNSL_TY2"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="CNSL_NEWTY2"
                v-on:change="getConsultTY('2')"
              >
                <template v-slot:label>
                  상담유형
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="CNSL_TY3"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="CNSL_NEWTY3"
                v-on:change="getConsultTY('3')"
              >
                <template v-slot:label>
                  상담유형
                </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="CNSL_TY4"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="CNSL_NEWTY4"
              >
                <template v-slot:label>
                  상담유형
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="box-ct d-flex mt-3">
          <div class="">

            <div class="form-inp sm">
              <v-select
                :items="USER_GP"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="USER_NEWGP"
              >
                <template v-slot:label>
                  사용자그룹
                </template>
              </v-select>
            </div>
            <v-text-field
                class="form-inp sm ml-2"
                name="name" label="사용자 ID" outlined hide-details
                v-model="USER_ID">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="사용자 이름" outlined hide-details
                v-model="USER_NM">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="사용자 닉네임" outlined hide-details
                v-model="USER_NICK">
              </v-text-field>
              <!-- 조회 버튼 -->
              <v-btn 
                v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
                dark small
                class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                @click="[btnUser(), showDialog('MP02')]">
                <v-icon small class="svg-main-searh white"></v-icon>
              </v-btn>
              <!-- 새로고침 버튼 -->
              <v-btn 
                v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
                dark small
                class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
                @click="initUser"
                plain
                >
                <v-icon small class="svg-main-refresh"></v-icon>
              </v-btn>
          </div>
          <div class="form-inp sm ml-2">
              <v-select
                :items="SORT_BY"
                item-value="CD"
                item-text="NM"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="SORT_NEWBY"
              >
                <template v-slot:label>
                  조회기준
                </template>
              </v-select>
            </div>

        </div>

      </div>
      <div class="ml-auto align-self-center mr-8">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="selectHDY">조회</v-btn>
      </div>
    </div>
    <!-- 상겸결과 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담결과
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="476px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="index"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
          @click:row="rowClick"
          single-select
          no-data-text="통계 결과가 없습니다."
          :item-class="isActiveRow"
          fixed-header
        >
          <template slot="body.append" v-if="gridDataText.length > 0">
            <tr class="grid-default-sum">
              <td class="grid-default-sum--head text-center" colspan="6">
                합계
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "SUM_TOT") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S08") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S09") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S10") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S11") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S12") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S13") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S14") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S15") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S16") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S17") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S18") }}
              </td>
              <td class="text-center">
                {{ mixin_sum_field(this.gridDataText, "S19") }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <div
          class="grid-paging text-center pt-2"
          v-if="gridDataText.length > 0"
        >
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 통계가 검색되었습니다.
        </p>
      </div>

    </div>
    <!--// 상담결과 -->

    <!-- 사용자 조회 모달창 -->
    <div class="">
      <v-dialog
        max-width="1400"
        max-height="1200"
        v-model="DialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
            headerTitle="사용자 조회"
            @hide="hideDialog('MP02')"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('MP02'), setUser()]"
          >
          </dialog-MP02>
          <template v-slot:body>

          </template>
      </v-dialog>
    </div>
    <!-- 사용자 조회 모달창 -->
  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import DialogMP02 from "./MP02.vue";

export default {
  name: "MENU_M710102", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogMP02,
  },
  data() {
    return{
      DialogMP02: false,
      menu1: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      dropItems: [
        {title:'option1'}
      ],
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        { text: '회사명', value: 'ASP_NEWCUST_KEY', align: 'center', sortable: false, },
        { text: '상담유형', value: 'CNSL_TYP_CD',align: 'center', sortable: false, },
        { text: '상담유형2', value: 'CNSL_TYP_CD_2', align: 'center',sortable: false, },
        { text: '상담유형3', value: 'CNSL_TYP_CD_3', align: 'center',sortable: false, },
        { text: '상담유형4', value: 'CNSL_TYP_CD_4', align: 'center',sortable: false, },
        { text: '합계', value: 'SUM_TOT', align: 'center',sortable: false, },
        { text: '~08', value: 'S08', align: 'center',sortable: false, },
        { text: '09', value: 'S09', align: 'center',sortable: false, },
        { text: '10', value: 'S10', align: 'center',sortable: false, },
        { text: '11', value: 'S11', align: 'center',sortable: false, },
        { text: '12', value: 'S12', align: 'center',sortable: false, },
        { text: '13', value: 'S13', align: 'center',sortable: false, },
        { text: '14', value: 'S14', align: 'center',sortable: false, },
        { text: '15', value: 'S15', align: 'center',sortable: false, },
        { text: '16', value: 'S16', align: 'center',sortable: false, },
        { text: '17', value: 'S17', align: 'center',sortable: false, },
        { text: '18', value: 'S18', align: 'center',sortable: false, },
        { text: '19~', value: 'S19', align: 'center',sortable: false, },
      ],
      gridDataText: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      ASP_CUST_KEY:this.$store.getters['userStore/GE_USER_ROLE'].company,

      CNSL_TY1:[
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      CNSL_TY2:[
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      CNSL_TY3:[
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      CNSL_TY4:[
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_GP:[
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      SORT_BY:[
        {
          CD: '',
          NM: '전체'
        },
        {
          CD: '1',
          NM: '콜기준'
        },
      ],

      ASP_NEWCUST_KEY:'',
      CNSL_NEWTY1:'',
      CNSL_NEWTY2:'',
      CNSL_NEWTY3:'',
      CNSL_NEWTY4:'',
      USER_NEWGP:'',
      USER_ID:'',
      USER_NM:'',
      USER_NICK:'',
      SORT_NEWBY:'',

      userInfo:{},
      
      ROW_DATA:{},
     }
  },

  created() {
  },

  methods: {
      startDate(e){
      this.dates[0] = e;
    },
    endDate(e){
      this.dates[1] = e;
    },

    //사용자 소속 콤보박스 생성
    getAttrDiv(){
      api.post("api/palette/common/get-attr-div/list",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        ATTR_CD: 'B',
        ATTR_DIV_CD: '',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "palette.common.get-attr-div",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.USER_GP = [...this.USER_GP, ...response.data.DATA];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //상담유형 select 불러오기
    getConsultTY(consult){
      var box = '';
      if(consult == "*") {
        this.CNSL_TY1 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY1 = '';
        this.CNSL_TY2 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY2 = '';
        this.CNSL_TY3 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY3 = '';
        this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY4 = '';
        box = consult;
      } else if(consult == "1"){
        this.CNSL_TY2 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY2 = '';
        this.CNSL_TY3 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY3 = '';
        this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY4 = '';
        box = this.CNSL_NEWTY1;
      } else if(consult == "2"){
        this.CNSL_TY3 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY3 = '';
        this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY4 = '';
        box = this.CNSL_NEWTY2;
      } else if(consult == "3"){
        this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
        this.CNSL_NEWTY4 = '';
        box = this.CNSL_NEWTY3;
      }
      api.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        SPST_CNSL_TYP_CD: box,
        USE_YN: "Y",
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "api.palette-main.aspcustkey",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        if(consult == "*") {
          this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
        } else if(consult == "1"){
          if (this.CNSL_NEWTY1 == ''){
            this.CNSL_TY2 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY2 = '';
            this.CNSL_TY3 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY3 = '';
            this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY4 = '';
          } else {
            this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
          }
        } else if(consult == "2"){
          if (this.CNSL_NEWTY2 == ''){
            this.CNSL_TY3 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY3 = '';
            this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY4 = '';
          }else {
            this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
          }
        } else if(consult == "3"){
          if (this.CNSL_NEWTY3 == ''){
            this.CNSL_TY4 = [{CD:'', CD_NM:'전체'}]
            this.CNSL_NEWTY4 = '';
          }else {
            this.CNSL_TY4 = [...this.CNSL_TY4, ...response.data.DATA];
          }
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    //조회버튼 클릭 이벤트
    selectHDY(){
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      let DT1 = this.dates[0];
      let DT1_Array = DT1.split("-");
      var ST_TIME = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];

      let DT2 = this.dates[1];
      let DT2_Array = DT2.split("-");
      var END_TIME = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

      api.post("api/statistics/phone/cnslt-type-by-time/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        DATE_FROM: ST_TIME,
        DATE_TO: END_TIME,
        CNSL_TYP_CD: this.CNSL_NEWTY1,
        CNSL_TYP_CD_2: this.CNSL_NEWTY2,
        CNSL_TYP_CD_3: this.CNSL_NEWTY3,
        CNSL_TYP_CD_4: this.CNSL_NEWTY4,
        DEPT_CD: this.USER_NEWGP,
        AGENT_ID: this.USER_ID,
        AGENT_NM: this.USER_NM,
        AGENT_NICK: this.USER_NICK,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "statistics.phone.cnslt-type-by-time",
        "METHOD" : "inqire",
        "TYPE" : "XML_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;
        for(var i=0; i<this.gridDataText.length;i++){
          this.gridDataText[i]["index"]= i+1;
        }
        this.page=1;
      })
      .catch((err) => {
        alert(err);
      })
    },

    chkAsp(){
      this.getAttrDiv();
      var user = {ASP_CUST_KEY:this.ASP_NEWCUST_KEY};
      this.userInfo = user;
      this.getConsultTY("*");
    },

    showDialog(menu) {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    initUser(){
      this.USER_ID = '';
      this.USER_NM = '';
      this.USER_NICK = '';
    },

    btnUser(){
      var user = {ASP_CUST_KEY:this.ASP_NEWCUST_KEY, USER_ID:this.USER_ID, USER_NM:this.USER_NM, USER_NICK:this.USER_NICK};
      this.userInfo = user;
    },

    setUser(){
      this.USER_ID = this.userInfo.USER_ID;
      this.USER_NM = this.userInfo.USER_NM;
      this.USER_NICK = this.userInfo.USER_NICK;
    },

    //Table row 클릭이벤트
    rowClick(item, row) {
      this.ROW_DATA = item;
      console.log("item",item);
      console.log("item.index",item.index);
      console.log("this.ROW_DATA.index",this.ROW_DATA.index);
    },

    isActiveRow(item){
      const activeClass = item.index === this.ROW_DATA.index ? 'active' : '';
      return activeClass;
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
  },

  },
}
</script>

<style>

</style>